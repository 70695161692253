import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 512.000000 512.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
		<path d="M0 2560 l0 -2560 2560 0 2560 0 0 2560 0 2560 -2560 0 -2560 0 0
-2560z m2935 2106 c305 -55 583 -181 840 -380 165 -128 377 -356 470 -505 6
-9 31 -47 56 -86 48 -74 156 -289 184 -365 61 -170 99 -305 125 -455 38 -210
30 -579 -17 -755 -8 -30 -18 -68 -23 -85 -37 -143 -127 -357 -205 -490 -35
-60 -124 -191 -178 -260 -64 -83 -235 -261 -300 -312 -34 -26 -69 -55 -77 -63
-20 -20 -207 -150 -215 -150 -4 0 -25 -13 -48 -28 -61 -41 -246 -129 -367
-174 -94 -35 -375 -108 -417 -108 -11 0 -2 13 25 38 79 73 82 87 82 419 l0
293 -37 40 -36 41 -75 -3 -74 -3 -2 -293 -1 -294 -24 -19 c-14 -11 -36 -19
-50 -17 l-26 3 -3 275 -2 274 -38 39 c-37 37 -37 38 -107 35 l-70 -3 -3 -279
c-3 -349 -1 -359 96 -451 37 -36 82 -72 100 -82 17 -10 32 -21 32 -25 0 -11
-113 -10 -255 3 -137 12 -357 57 -460 95 -38 14 -88 32 -111 40 -116 40 -134
55 -77 67 46 10 107 56 138 105 35 55 42 123 26 241 -13 95 -25 122 -81 177
l-42 42 23 21 c38 35 132 19 117 -20 -3 -8 -1 -41 4 -72 10 -56 12 -59 70 -99
l61 -41 18 24 c37 49 43 92 30 206 -13 114 -30 158 -81 211 -46 49 -103 72
-178 72 -100 0 -174 -48 -218 -144 -28 -61 -30 -114 -8 -222 20 -96 25 -107
78 -162 l41 -43 -24 -20 c-26 -21 -95 -26 -107 -7 -4 6 -9 41 -10 77 -3 60 -6
68 -36 92 -17 15 -46 34 -64 44 -43 23 -58 12 -81 -61 -15 -48 -15 -63 -4
-136 8 -46 19 -100 26 -121 7 -20 9 -37 3 -37 -30 0 -273 209 -373 321 -70 78
-180 232 -219 305 -15 27 -36 65 -48 84 -30 50 -112 244 -133 315 -10 33 -23
78 -31 101 -91 287 -105 702 -33 1039 6 28 14 61 19 75 4 14 13 45 20 70 38
138 90 262 174 415 189 348 444 627 781 856 114 78 209 122 380 179 55 19 109
37 120 41 90 34 300 82 455 104 125 17 355 13 475 -9z m-1089 -3196 c86 -42
133 -116 134 -208 0 -55 -21 -122 -39 -122 -14 0 -14 1 4 43 39 94 -5 214 -96
263 -40 22 -141 24 -179 5 -85 -45 -129 -139 -108 -235 8 -39 23 -62 69 -112
69 -74 81 -113 53 -170 -25 -49 -62 -75 -115 -81 -81 -9 -155 68 -145 151 4
37 20 65 31 55 2 -3 -1 -21 -7 -41 -29 -94 100 -187 176 -127 14 11 33 34 41
49 27 53 20 77 -45 144 -94 98 -112 173 -66 271 56 119 181 168 292 115z m-6
-41 c64 -39 100 -102 100 -173 0 -34 -26 -96 -39 -96 -14 0 -14 1 5 45 28 67
-3 155 -68 195 -42 25 -128 27 -168 3 -40 -23 -70 -82 -70 -138 0 -58 10 -78
75 -140 52 -50 65 -81 65 -149 -1 -48 -34 -110 -75 -142 -32 -24 -47 -29 -99
-29 -76 0 -135 34 -166 96 -26 50 -29 130 -5 165 22 33 31 23 15 -16 -7 -17
-10 -50 -7 -76 9 -81 65 -135 150 -142 111 -9 180 69 162 184 -5 38 -16 55
-65 103 -32 32 -62 70 -66 85 -12 49 1 133 27 170 48 72 158 98 229 55z m-19
-44 c60 -32 91 -133 54 -181 -14 -17 -15 -15 -9 33 7 59 -11 100 -56 127 -74
43 -170 -10 -170 -95 0 -40 21 -72 83 -128 71 -64 84 -178 31 -269 -73 -124
-230 -146 -341 -49 -82 72 -104 197 -49 272 22 30 28 13 10 -24 -24 -47 -16
-148 15 -193 39 -57 85 -82 157 -86 50 -3 72 0 107 17 128 62 144 248 29 341
-61 48 -79 119 -47 181 32 62 121 87 186 54z m549 -428 c0 -307 0 -309 64
-365 72 -63 180 -63 253 1 62 55 63 58 63 361 0 269 3 312 21 300 13 -8 11
-530 -2 -579 -15 -54 -93 -130 -151 -145 -77 -21 -155 0 -212 56 -60 61 -61
64 -64 367 -2 153 -1 286 3 295 18 47 25 -35 25 -291z m40 17 c0 -296 1 -304
54 -353 19 -18 41 -26 81 -29 73 -6 110 11 140 63 25 42 25 43 25 318 0 173 4
278 10 282 7 4 10 -92 10 -280 0 -313 -1 -319 -60 -369 -72 -60 -159 -55 -234
14 l-41 39 -3 301 c-2 208 0 300 8 295 6 -4 10 -101 10 -281z m280 5 c0 -295
-2 -308 -59 -348 -33 -24 -102 -28 -140 -8 -13 6 -32 28 -42 47 -17 31 -19 61
-19 307 0 175 4 273 10 273 6 0 10 -95 10 -266 0 -295 5 -321 61 -344 39 -17
107 -7 133 20 20 20 21 31 24 311 2 181 6 287 12 284 6 -4 10 -115 10 -276z"/>
<path d="M2461 4643 c-13 -3 -47 -28 -76 -56 -65 -65 -75 -103 -75 -276 0 -98
4 -144 19 -190 20 -66 76 -148 119 -177 76 -51 61 -49 292 -44 19 0 51 -1 70
-2 l35 -3 5 100 5 100 30 5 c28 5 30 8 33 56 2 43 -1 56 -23 82 -24 29 -25 35
-25 155 0 142 -6 167 -58 220 -32 32 -42 37 -83 37 -32 0 -49 -5 -52 -15 -5
-13 -10 -13 -41 0 -33 14 -121 18 -175 8z m164 -23 c52 -18 60 -18 68 -5 5 9
22 15 43 15 l35 0 -3 -147 -3 -148 -49 0 -50 0 -12 69 c-23 122 -71 182 -131
161 -46 -17 -53 -44 -53 -226 0 -191 3 -215 35 -255 19 -24 30 -29 64 -28 50
2 78 24 87 70 8 44 -1 69 -32 83 -20 9 -25 17 -22 39 l3 27 108 3 107 3 0 -34
c0 -30 -4 -35 -29 -40 -35 -7 -41 -26 -41 -130 l0 -77 -52 1 c-29 1 -80 -2
-113 -6 -120 -15 -208 39 -240 147 -23 80 -21 310 5 363 31 65 67 99 127 121
52 18 81 17 148 -6z m-6 -147 c5 -10 12 -45 16 -78 4 -33 9 -68 11 -77 4 -14
-1 -18 -25 -18 -31 0 -33 -3 -33 -50 0 -47 3 -56 24 -65 25 -11 33 -34 24 -71
-9 -32 -53 -34 -65 -1 -12 33 -13 334 -1 358 13 24 36 25 49 2z"/>
<path d="M2481 4609 c-82 -33 -131 -113 -131 -216 l0 -63 50 0 50 0 0 85 c0
123 27 175 90 175 63 0 111 -64 132 -179 11 -60 12 -61 45 -61 l33 0 0 130 c0
136 -4 147 -41 114 -17 -15 -23 -15 -80 5 -67 22 -110 25 -148 10z m-68 -243
l-15 -28 -14 27 c-19 36 -18 45 8 102 l22 48 7 -60 c5 -45 3 -67 -8 -89z"/>
<path d="M2381 4296 c-12 -22 -12 -34 4 -91 10 -37 21 -63 25 -58 4 4 10 36
13 70 5 50 3 65 -11 83 l-18 22 -13 -26z"/>
<path d="M1193 4008 c-40 -40 -73 -79 -73 -86 0 -6 -5 -12 -10 -12 -6 0 -29
-19 -52 -42 -22 -24 -63 -67 -91 -95 l-51 -53 24 -25 c20 -22 30 -25 65 -20
40 5 41 5 150 -101 200 -193 291 -230 427 -176 61 25 128 123 128 187 0 67
-27 106 -162 237 l-131 128 7 38 c8 55 -20 80 -100 88 l-59 6 -72 -74z m96 28
c6 -7 8 -27 5 -45 -6 -31 0 -39 129 -170 96 -96 143 -151 158 -185 20 -46 20
-50 4 -92 -18 -48 -70 -99 -123 -121 -69 -29 -140 9 -277 148 -111 112 -157
144 -186 129 -22 -12 -49 1 -49 24 0 6 33 45 74 85 87 86 112 90 101 18 l-7
-43 137 -132 c149 -143 187 -166 230 -138 34 23 48 53 41 91 -5 25 -37 63
-136 161 -72 71 -140 133 -151 138 -14 7 -27 6 -41 -3 -18 -11 -23 -10 -39 6
-17 17 -16 20 39 80 60 65 72 72 91 49z m119 -310 c105 -108 120 -138 74 -144
-18 -3 -47 20 -128 99 -103 101 -119 130 -92 157 17 17 32 5 146 -112z"/>
<path d="M1217 3982 c-26 -26 -47 -51 -47 -55 0 -4 15 -5 33 -2 35 7 58 -7
146 -92 24 -24 48 -43 53 -43 20 0 3 24 -63 89 -70 68 -72 71 -66 111 3 22 2
40 -2 40 -4 0 -28 -22 -54 -48z"/>
<path d="M1029 3791 c-33 -33 -59 -61 -59 -64 0 -2 18 -4 40 -3 34 1 46 -6 85
-44 53 -52 69 -47 31 10 -28 43 -24 50 12 24 36 -25 36 -10 2 27 -33 36 -46
70 -36 94 11 31 -19 13 -75 -44z"/>
<path d="M1207 3639 c6 -20 3 -21 -21 -15 -31 8 -33 -9 -3 -28 21 -14 63 24
54 48 -10 25 -36 20 -30 -5z"/>
<path d="M1230 3589 c0 -23 23 -50 59 -69 21 -10 41 -17 44 -13 11 10 -65 93
-84 93 -11 0 -19 -5 -19 -11z"/>
<path d="M3784 4016 c-47 -21 -107 -79 -196 -190 -107 -134 -130 -181 -136
-276 -6 -99 19 -161 93 -228 101 -93 226 -96 337 -10 60 47 215 240 237 294
33 84 36 150 11 224 -26 77 -68 133 -129 172 -39 24 -55 28 -115 28 -39 -1
-85 -7 -102 -14z m168 -10 c56 -20 95 -56 124 -114 22 -44 26 -61 21 -106 -7
-71 -32 -117 -124 -228 -89 -108 -105 -124 -153 -148 -109 -55 -235 -18 -297
88 -31 52 -35 135 -10 192 18 39 188 247 229 279 59 47 141 61 210 37z"/>
<path d="M3792 3975 c-29 -15 -72 -50 -97 -78 l-46 -52 38 -32 c21 -18 41 -33
45 -33 3 0 29 29 58 64 85 108 146 131 201 77 50 -50 36 -97 -57 -207 -24 -28
-44 -54 -44 -58 0 -11 33 -42 59 -56 21 -11 26 -9 47 16 60 72 79 116 79 184
0 78 -22 121 -83 164 -60 42 -130 46 -200 11z m-2 -87 c-28 -39 -76 -75 -86
-66 -2 2 1 20 6 39 8 28 21 42 58 62 26 14 48 22 50 17 2 -5 -11 -28 -28 -52z
m240 -145 c-20 -67 -32 -91 -48 -96 -9 -3 -25 -8 -35 -12 -32 -13 -9 46 37 92
49 49 57 52 46 16z"/>
<path d="M3898 3923 c-19 -5 -239 -264 -277 -325 -31 -51 -26 -98 12 -116 48
-23 95 9 192 129 10 13 52 64 92 113 85 105 98 144 62 182 -21 23 -42 27 -81
17z m52 -115 c0 -25 -238 -308 -259 -308 -24 0 -7 28 90 149 122 152 169 196
169 159z"/>
<path d="M3647 3793 c-16 -4 -74 -110 -65 -118 9 -9 98 70 98 86 0 10 0 22 1
26 1 10 -11 12 -34 6z"/>
<path d="M3882 3600 c-12 -5 -25 -18 -29 -27 -4 -10 -18 -35 -31 -56 -14 -20
-22 -40 -19 -43 7 -8 104 63 111 82 8 22 7 54 -1 53 -5 0 -18 -4 -31 -9z"/>
<path d="M2375 3783 c-16 -2 -59 -11 -95 -19 -237 -57 -428 -163 -596 -331
-326 -328 -445 -809 -305 -1231 72 -215 163 -366 305 -507 199 -196 432 -310
716 -350 139 -19 186 -19 316 0 278 42 503 154 701 349 242 241 363 530 363
871 0 186 -34 350 -105 503 -14 29 -25 55 -25 58 0 3 -33 56 -74 117 -186 281
-456 460 -798 531 -72 15 -316 20 -403 9z m424 -298 c51 -15 125 -42 163 -60
161 -79 189 -110 239 -265 25 -79 30 -112 32 -205 2 -132 -15 -203 -76 -325
-40 -81 -43 -85 -72 -82 -49 6 -126 54 -166 104 -20 26 -49 80 -64 120 -47
124 -141 251 -236 321 -36 27 -49 27 -49 -2 0 -50 -98 -175 -147 -187 -13 -4
-23 -10 -23 -14 0 -4 -17 -11 -37 -15 -21 -3 -74 -26 -119 -50 -50 -27 -86
-40 -94 -35 -9 6 -7 10 9 15 23 7 28 21 9 28 -9 3 -8 10 6 26 11 11 14 21 8
21 -6 0 -15 -7 -20 -15 -5 -10 -15 -13 -25 -9 -21 8 -22 20 -2 28 13 5 15 40
15 236 0 195 2 232 15 236 11 5 12 11 4 25 -7 14 -17 18 -37 14 l-27 -6 25 15
c14 8 31 15 38 16 7 0 28 11 45 24 33 25 114 50 215 66 96 15 266 4 371 -25z
m-649 -115 c-8 -6 -22 -10 -30 -10 -8 0 -4 6 10 13 27 15 44 12 20 -3z m510
-536 c72 -21 111 -49 152 -108 68 -97 72 -211 11 -316 -32 -55 -63 -83 -128
-118 -41 -22 -63 -27 -125 -27 -90 0 -152 26 -217 90 -87 86 -111 222 -59 329
63 129 220 193 366 150z m-537 -88 c-10 -13 -27 -30 -38 -37 -19 -11 -19 -11
-5 7 26 34 45 53 53 53 4 1 0 -10 -10 -23z m1352 -16 c3 -5 1 -10 -4 -10 -6 0
-11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-1389 -159 c56 -26 125 -96 148
-151 48 -114 89 -196 118 -234 39 -50 117 -129 151 -151 22 -14 25 -23 26 -75
0 -70 25 -133 76 -195 47 -58 109 -86 193 -87 66 -1 66 -1 35 -14 -49 -21
-196 -44 -278 -44 -156 0 -350 53 -482 132 -94 57 -112 79 -152 190 -33 90
-44 171 -39 291 6 119 25 190 80 300 32 65 51 71 124 38z m1394 -175 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z"/>
<path d="M2497 2804 c-45 -30 -47 -44 -7 -44 17 0 30 5 30 11 0 6 7 8 17 5 10
-4 14 -4 10 0 -4 4 -2 13 3 19 7 9 6 16 -3 23 -10 9 -22 5 -50 -14z"/>
<path d="M2581 2816 c-9 -11 -9 -16 -1 -21 6 -4 9 -13 6 -20 -3 -9 6 -16 27
-20 18 -4 37 -12 44 -17 7 -5 17 -7 23 -3 15 9 -25 62 -60 80 -24 12 -30 12
-39 1z"/>
<path d="M2382 2764 c-24 -17 -30 -44 -9 -44 16 0 47 32 47 48 0 16 -11 15
-38 -4z"/>
<path d="M2722 2759 c4 -29 33 -48 49 -32 9 9 7 17 -12 32 -31 26 -41 26 -37
0z"/>
<path d="M2318 2683 c-8 -10 -20 -33 -28 -51 -10 -26 -10 -35 0 -47 22 -26 43
-13 56 35 6 25 15 53 19 63 5 13 2 17 -12 17 -11 0 -27 -8 -35 -17z"/>
<path d="M2765 2690 c-7 -11 4 -77 17 -102 11 -22 54 -24 61 -4 8 20 -10 82
-29 101 -18 17 -40 20 -49 5z"/>
<path d="M2288 2533 c-8 -37 18 -98 45 -107 39 -14 43 -1 22 62 -15 43 -25 58
-42 60 -14 2 -23 -3 -25 -15z"/>
<path d="M2780 2529 c-6 -11 -12 -39 -13 -62 -2 -38 0 -42 23 -42 20 0 28 8
42 49 14 37 16 52 7 62 -17 20 -47 17 -59 -7z"/>
<path d="M2360 2387 c0 -8 11 -24 25 -37 35 -32 54 -14 25 23 -23 29 -50 36
-50 14z"/>
<path d="M2614 2387 c-23 -12 -46 -61 -37 -83 9 -24 38 -16 82 22 40 36 53 64
29 64 -7 0 -22 2 -33 4 -11 2 -29 -1 -41 -7z"/>
<path d="M2725 2369 c-21 -40 -12 -48 23 -22 34 26 41 53 13 53 -13 0 -27 -12
-36 -31z"/>
<path d="M2450 2366 c0 -20 69 -78 88 -74 28 5 21 51 -8 59 l-25 7 25 2 c23 1
23 2 5 10 -31 13 -57 12 -49 -2 6 -10 4 -10 -7 0 -17 16 -29 15 -29 -2z"/>
<path d="M904 2816 c-6 -6 -70 -69 -142 -140 -73 -71 -129 -133 -126 -137 3
-5 22 -9 42 -9 l37 0 0 -112 0 -113 203 -3 c158 -2 202 0 202 10 0 10 -42 12
-195 10 l-195 -3 0 111 0 110 -42 0 -42 0 109 114 c61 62 115 112 122 110 7
-2 10 2 7 9 -2 7 5 18 17 25 20 13 26 9 80 -44 40 -40 59 -53 59 -41 0 13 9
17 40 17 22 0 40 5 40 10 0 13 -73 13 -87 -1 -7 -7 -22 2 -49 31 -48 51 -66
61 -80 46z"/>
<path d="M4266 2759 c27 -28 84 -85 126 -126 42 -42 75 -80 72 -85 -3 -4 -18
-6 -34 -4 l-30 3 0 -113 0 -114 -200 0 c-127 0 -200 -4 -200 -10 0 -6 75 -10
205 -10 l205 0 0 115 0 115 34 0 c19 0 38 5 42 11 6 11 -242 268 -260 269 -5
0 13 -23 40 -51z"/>
<path d="M4127 2762 c-14 -15 -28 -18 -72 -14 l-55 4 0 -59 c0 -56 -2 -61 -40
-98 -51 -50 -51 -65 -1 -65 l40 0 4 -52 c4 -51 4 -51 3 7 -1 59 -1 60 -31 61
-16 1 -34 2 -38 3 -5 1 10 20 32 44 37 38 41 47 41 95 l0 52 34 0 c23 0 36 -5
38 -16 2 -11 12 -6 37 20 19 20 32 36 30 36 -3 0 -13 -8 -22 -18z"/>
<path d="M1112 2674 c-2 -45 1 -53 33 -84 19 -19 35 -38 35 -42 0 -4 -16 -8
-35 -8 l-35 0 2 -57 2 -58 3 53 4 52 40 0 c50 0 50 15 -1 65 -32 31 -40 46
-43 84 l-3 46 -2 -51z"/>
<path d="M1025 2583 c-70 -36 -133 -122 -148 -200 -6 -31 -5 -33 22 -33 26 0
29 4 34 41 8 58 50 114 108 141 36 17 48 29 49 46 0 26 -21 28 -65 5z"/>
<path d="M4030 2576 c0 -18 7 -25 32 -30 45 -9 115 -85 123 -133 10 -58 13
-63 36 -63 20 0 21 4 16 45 -8 53 -41 109 -92 154 -62 54 -115 67 -115 27z"/>
<path d="M1053 2490 c-64 -39 -105 -139 -60 -146 13 -2 22 9 34 37 8 22 26 48
40 59 17 13 23 27 21 45 l-3 25 -32 -20z"/>
<path d="M4030 2477 c0 -12 9 -28 21 -35 11 -7 29 -33 40 -57 15 -35 23 -42
40 -38 17 5 19 11 13 37 -16 75 -114 156 -114 93z"/>
<path d="M1070 2390 c-12 -23 6 -50 34 -50 23 0 40 38 25 56 -17 20 -47 17
-59 -6z"/>
<path d="M3991 2396 c-18 -22 4 -58 32 -54 31 4 32 62 1 66 -11 2 -26 -4 -33
-12z"/>
<path d="M4283 1922 c-11 -7 -16 -26 -14 -49 0 -7 11 -13 24 -13 12 0 45 -18
72 -40 28 -22 55 -40 61 -40 12 0 34 42 34 65 0 8 -8 15 -18 15 -9 0 -39 16
-66 35 -49 35 -71 41 -93 27z"/>
<path d="M4231 1814 c-28 -20 -28 -54 0 -54 11 0 46 -20 78 -45 31 -25 60 -45
64 -45 11 0 38 60 32 70 -3 6 -12 10 -20 10 -7 0 -33 15 -57 33 -57 44 -72 48
-97 31z"/>
<path d="M4155 1700 c-15 -29 -15 -30 17 -44 40 -17 108 -76 108 -93 0 -26 27
-12 48 26 27 47 27 51 3 51 -10 0 -45 20 -77 45 -31 25 -63 45 -70 45 -8 0
-20 -13 -29 -30z"/>
<path d="M4103 1600 c-33 -13 -28 -36 8 -44 18 -3 51 -25 77 -51 24 -25 49
-42 54 -39 17 11 1 86 -24 110 -26 26 -83 38 -115 24z"/>
<path d="M4034 1515 c-20 -79 88 -156 154 -109 20 14 22 42 2 29 -10 -6 -99
59 -118 87 -18 25 -30 23 -38 -7z"/>
<path d="M3304 1478 c-134 -74 -164 -94 -164 -107 0 -8 34 -73 76 -145 42 -72
102 -174 132 -226 63 -109 107 -180 122 -197 5 -6 30 -15 55 -18 42 -7 51 -4
140 50 53 31 108 69 122 84 89 92 63 259 -49 317 -23 12 -28 21 -28 50 0 47
-24 102 -63 142 -23 24 -54 39 -112 57 -111 34 -158 33 -231 -7z m195 -6 c59
-29 94 -83 99 -151 4 -55 6 -59 38 -74 43 -21 90 -86 99 -136 8 -50 -9 -112
-41 -150 -23 -28 -211 -147 -219 -139 -2 2 -44 73 -93 158 -49 85 -113 196
-142 245 -90 155 -87 140 -38 173 24 16 70 43 102 61 69 36 140 41 195 13z"/>
<path d="M3282 1424 c-46 -25 -86 -52 -89 -60 -3 -7 4 -28 15 -46 10 -18 77
-131 147 -251 70 -119 131 -217 136 -217 18 0 162 95 184 122 31 36 49 101 40
145 -8 42 -83 123 -113 123 -20 0 -22 5 -22 55 0 70 -22 115 -73 149 -65 42
-122 37 -225 -20z m215 6 c53 -32 80 -112 59 -178 -6 -17 -1 -22 31 -32 93
-30 135 -117 99 -204 -15 -36 -51 -66 -137 -115 l-57 -31 -58 97 c-124 211
-224 384 -224 388 0 6 63 46 116 74 51 27 128 28 171 1z"/>
<path d="M3321 1404 c-40 -20 -75 -42 -78 -50 -6 -16 107 -214 122 -214 6 0
45 20 87 45 62 36 78 51 88 79 22 66 -15 140 -83 165 -43 17 -59 14 -136 -25z
m163 -9 c54 -50 57 -112 8 -157 -29 -27 -121 -83 -126 -77 -8 8 -106 178 -106
183 0 3 28 21 63 40 76 44 122 47 161 11z"/>
<path d="M3338 1373 c-27 -14 -48 -30 -48 -36 0 -19 82 -147 94 -147 25 0 104
61 116 89 9 23 8 35 -3 64 -27 63 -80 73 -159 30z m146 -49 c8 -29 -16 -74
-38 -74 -13 0 -46 41 -46 57 0 10 51 42 66 43 7 0 15 -12 18 -26z"/>
<path d="M3448 1159 c-38 -22 -68 -45 -67 -52 0 -7 18 -41 39 -77 22 -36 47
-80 57 -97 9 -18 20 -33 24 -33 15 0 127 71 149 95 15 16 24 41 28 75 4 48 3
52 -36 90 -55 56 -101 56 -194 -1z m147 17 c39 -17 75 -68 75 -108 0 -35 -32
-70 -102 -112 -52 -31 -58 -32 -71 -17 -19 21 -97 157 -97 168 0 9 135 83 152
83 5 0 24 -6 43 -14z"/>
<path d="M3473 1133 c-24 -13 -43 -30 -43 -36 0 -15 79 -147 88 -147 17 0 101
60 111 79 19 34 13 80 -14 106 -32 32 -85 32 -142 -2z m149 -45 c5 -31 -20
-80 -38 -76 -9 2 -23 16 -32 31 -17 27 -16 29 8 47 34 26 58 25 62 -2z"/>
<path d="M3967 1442 c-28 -31 -21 -42 28 -42 52 0 60 18 20 44 -30 20 -28 20
-48 -2z"/>
<path d="M3897 1373 c-21 -21 13 -105 50 -122 33 -15 98 -14 112 2 13 16 5 27
-20 27 -9 0 -41 23 -70 50 -54 51 -61 55 -72 43z"/>
<path d="M4095 1359 c-10 -27 -10 -58 0 -61 16 -5 31 50 17 64 -9 9 -13 8 -17
-3z"/>
<path d="M3898 1189 c-24 -13 -23 -46 1 -54 38 -12 73 23 50 51 -13 16 -27 17
-51 3z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
